.migratePatientModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20000;
  transition: all 0.66s ease-out;
}

.migratePatientModal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: calc(70px + 5vh);
  margin: 0 auto;
  background: #fff;
  width: clamp(340px, 50%, 100%);
  height: clamp(320px, 50vh, calc(80vh - 70px));
  border-radius: 4px;
  background: #f8f8fa;
  transition: all 0.66s ease-out;
}
